.socials_buttons{
    margin: 3% 17% 2% 17%;
    display:flex;
    justify-content: space-between;
    color: #5a5e62;
}

.action_button{
    display:flex;
    z-index: 10;
}

.action_button p{
    /* position: absolute; */
    margin-top: -6%;
    margin-left: 10%;
}

.action_button:hover{
    cursor: pointer;
}

.replies_button:hover{
    color: #5ed9ff;
}

.replies_shadow{
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: rgba(94, 217, 255, 0.25);
    margin-left: -.55%;
    margin-top: -.5%;
    z-index: 1;
}


.retweet_button:hover{
    color:#6ae630;
}

.retweet_shadow{
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: rgb(106, 230, 48, 0.25);
    margin-left: -.5%;
    margin-top: -.5%;
    z-index: 1;
}

.likes_button:hover{
    color: #f91880;
}

.like_shadow{
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: rgb(249, 24, 128, 0.2);
    margin-left: -.5%;
    margin-top: -.5%;
    z-index: 1;
}

.share_button:hover{
    color: #5ed9ff;
}

.share_shadow{
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: rgba(94, 217, 255, 0.25);
    margin-left: -.39%;
    margin-top: -.5%;
    z-index: 1;
}

.share_message{
    position: absolute;
    margin-top: -15%;
    margin-left: 50%;
    width: 15%;
    /* background-color: rgba(127, 126, 126, 0.197); */
    border-radius: 10px;
    text-align: center;
    font-weight: 700;
    color: white;
}

.share_message img{
    border-radius: 10px;
}