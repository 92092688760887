.navbar{
    display:flex;
    justify-content: space-evenly;
}

.navItem{
    color: rgb(122, 122, 122);
    margin-top: 1em;
}

.navItem:hover{
    color: rgb(94, 217, 255);
    cursor: pointer;
}

.nav_selector{
    margin-top: 10%;
    margin-bottom: -20%;
    height: 3px;
    border-radius: 50px;
    background-color: rgb(18, 137, 255);
}

.selected{
    color: white;
}
.selected_light{
    color: black;
}

.hidden{
    display: none;
}