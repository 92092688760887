.contact_input::placeholder{
    font-family: 'Inconsolata', monospace;
}

.contact_input{
    border-radius: 5px;
}

.submit_button{
    border-radius: 5px;
    background-color: transparent;
    color: rgb(18, 137, 255);
}
