
body{
  font-family: 'Inconsolata', monospace;
  overflow: overlay;
}

.App{
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
}

.dark_mode{
  background-color:rgba(0, 0, 0, 0.5);
  color: white;
}

/* Monitor size */
@media screen and (min-width: 430px) {
  .App {
    left:50%;
    position:absolute;
    transform: translate(-50%, 0%);
    text-align: left;
    width: 40%;
  }

}

/* Mobile size */
@media screen and (max-width: 1000px) {

  .App{
    width:100%
  }
  
 
}